import React, { useEffect, useState } from 'react'

function CharacterCash({ character, editMode, handleSaveCharacter }) {
  const [cash, setCash] = useState({ ...character.cash })
  const [addIndex, setAddIndex] = useState(1)

  useEffect(() => {
    if (!editMode) {
      setCash({ ...character.cash })
    }
  }, [editMode, character])

  const handleUpdateCash = (value) => {
    setCash({ ...cash, cash: value })
  }

  const handleAddProperty = () => {
    const updatedCash = { ...cash }
    updatedCash.properties.push(`Nuova proprietà ${addIndex}`)
    setAddIndex(addIndex + 1)
    setCash(updatedCash)
  }

  const handleChangeProperty = (index, value) => {
    const updatedCash = { ...cash }
    updatedCash.properties[index] = value
    setCash(updatedCash)
  }

  return (
    <div className="character-sheetpage_cash">
      <div>
        <b>Livello di spesa:</b> {cash.spendinglevel}
      </div>
      {editMode ? (
        <div>
          <b>Cash:</b>
          <input
            type="text"
            value={cash.cash}
            onChange={(e) => handleUpdateCash(e.target.value)}
          />
        </div>
      ) : (
        <div>
          <b>Cash:</b> {cash.cash}
        </div>
      )}
      <div>
        <b>Proprietà:</b>{' '}
        {cash.properties.map((item, index) => {
          if (editMode) {
            return (
              <div key={index}>
                <input
                  type="text"
                  value={item}
                  onChange={(e) => handleChangeProperty(index, e.target.value)}
                />
              </div>
            )
          } else {
            return <div key={index}>{item}</div>
          }
        })}
      </div>
      {editMode && (
        <>
          <button
            onClick={() =>
              handleSaveCharacter({
                ...character,
                cash: {
                  ...character.cash,
                  cash: cash.cash,
                  properties: cash.properties,
                },
              })
            }
          >
            Salva
          </button>
          <button onClick={() => handleAddProperty()}>
            Aggiungi proprietà
          </button>
        </>
      )}
    </div>
  )
}

export default CharacterCash
