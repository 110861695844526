import React, { useEffect, useState } from 'react'

function CharacterWeapons({
  character,
  handleDiceRoll,
  editMode,
  handleSaveCharacter,
}) {
  const [weapons, setWeapons] = useState([...character.weapons])
  const [addIndex, setAddIndex] = useState(1)

  useEffect(() => {
    if (!editMode) {
      setWeapons([...character.weapons])
    }
  }, [editMode, character])

  const handleUpdateWeapons = (index, field, value) => {
    const findSkillValue = (skillName) => {
      const skill = character.skills.find((s) => s.name === skillName)
      return skill ? skill.value : '0'
    }
    const updatedWeapons = [...weapons]
    updatedWeapons[index][field] = value
    updatedWeapons.map((weapon) => {
      weapon.value = findSkillValue(weapon.associatedSkill)
      return weapon
    })
    setWeapons(updatedWeapons)
  }

  const handleAddWeapon = () => {
    const updatedWeapons = [...weapons]
    updatedWeapons.push({
      name: `Nuova arma ${addIndex}`,
      value: '0',
      associatedSkill: '',
      damage: '',
      range: '',
      attacks: '',
      ammo: '',
      malfunction: '',
    })
    setAddIndex(addIndex + 1)
    setWeapons(updatedWeapons)
  }

  const weaponSkills = character.skills.filter(
    (skill) =>
      skill.name.indexOf('Armi') === 0 ||
      skill.name.indexOf('Combattere') === 0 ||
      skill.name.indexOf('Lanciare') === 0 ||
      skill.name.indexOf('Demolizioni') === 0 ||
      skill.name.indexOf('Artiglieria') === 0 ||
      skill.name.indexOf('Rip. elettriche') === 0
  )

  const BD = character.points.find((point) => point.name === 'Bonus al Danno')
  const evaluateDamage = (damage) => {
    return damage.replace(/BD/g, `BD (${BD.value})`)
  }

  return (
    <>
      <div className="character-sheetpage_weapons">
        <div className="character-sheetpage_weapons_row">
          <div className="row_weapon_name">Armi</div>
          <div className="row_weapon_value">Valore</div>
          <div className="row_weapon_damage">Danno</div>
          <div className="row_weapon_range">Gittata</div>
          <div className="row_weapon_attacks">Attacchi</div>
          <div className="row_weapon_ammo">Munizioni</div>
          <div className="row_weapon_malfunction">Malfunzionamento</div>
        </div>
        {editMode
          ? weapons.map((weapon, index) => (
              <div
                key={`weapons_${index}`}
                className="character-sheetpage_weapons_row"
              >
                <div className="row_weapon_name">
                  <input
                    type="text"
                    value={weapon.name}
                    onChange={(e) =>
                      handleUpdateWeapons(index, 'name', e.target.value)
                    }
                  />
                </div>
                <div className="row_weapon_value">
                  <select
                    value={weapon.associatedSkill}
                    onChange={(e) =>
                      handleUpdateWeapons(
                        index,
                        'associatedSkill',
                        e.target.value
                      )
                    }
                  >
                    <option value="">-</option>
                    {weaponSkills.map((skill, index) => (
                      <option
                        key={index}
                        value={skill.name}
                        defaultChecked={skill.name === weapon.associatedSkill}
                      >
                        {skill.name} ({skill.value})
                      </option>
                    ))}
                  </select>
                </div>
                <div className="row_weapon_damage">
                  <input
                    type="text"
                    value={weapon.damage}
                    onChange={(e) =>
                      handleUpdateWeapons(index, 'damage', e.target.value)
                    }
                  />
                </div>
                <div className="row_weapon_range">
                  <input
                    type="text"
                    value={weapon.range}
                    onChange={(e) =>
                      handleUpdateWeapons(index, 'range', e.target.value)
                    }
                  />
                </div>
                <div className="row_weapon_attacks">
                  <input
                    type="text"
                    value={weapon.attacks}
                    onChange={(e) =>
                      handleUpdateWeapons(index, 'attacks', e.target.value)
                    }
                  />
                </div>
                <div className="row_weapon_ammo">
                  <input
                    type="text"
                    value={weapon.ammo}
                    onChange={(e) =>
                      handleUpdateWeapons(index, 'ammo', e.target.value)
                    }
                  />
                </div>
                <div className="row_weapon_malfunction">
                  <input
                    type="text"
                    value={weapon.malfunction}
                    onChange={(e) =>
                      handleUpdateWeapons(index, 'malfunction', e.target.value)
                    }
                  />
                </div>
              </div>
            ))
          : weapons.map((weapon, index) => (
              <div
                key={`weapons_${index}`}
                className="character-sheetpage_weapons_row weapon-hover"
                onClick={() => handleDiceRoll(weapon)}
              >
                <div className="row_weapon_name">{weapon.name}</div>
                <div className="row_weapon_value">{weapon.value}</div>
                <div className="row_weapon_damage">
                  {evaluateDamage(weapon.damage)}
                </div>
                <div className="row_weapon_range">{weapon.range}</div>
                <div className="row_weapon_attacks">{weapon.attacks}</div>
                <div className="row_weapon_ammo">{weapon.ammo}</div>
                <div className="row_weapon_malfunction">
                  {weapon.malfunction}
                </div>
              </div>
            ))}
      </div>
      {editMode && (
        <div className="character-sheetpage_weapons_submit">
          <button
            onClick={() => handleSaveCharacter({ ...character, weapons })}
          >
            Salva
          </button>
          <button onClick={() => handleAddWeapon()}>Aggiungi Arma</button>
        </div>
      )}
    </>
  )
}

export default CharacterWeapons
