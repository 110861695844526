import React, { useEffect, useState } from 'react'

function CharacterCharacteristics({
  character,
  handleDiceRoll,
  editMode,
  handleSaveCharacter,
}) {
  const [characteristics, setCharacteristics] = useState([
    ...character.characteristics,
  ])

  useEffect(() => {
    if (!editMode) {
      setCharacteristics([...character.characteristics])
    }
  }, [editMode, character])

  const handleUpdateCharacteristic = (characteristic) => {
    setCharacteristics(
      characteristics.map((c) =>
        c.name === characteristic.name ? characteristic : c
      )
    )
  }

  return (
    <div className="character-sheetpage_characteristics">
      {characteristics.map((c) => (
        <div
          key={`characteristics_${c.name}`}
          className="character-sheetpage_characteristics_stat character-hover"
          onClick={() => (editMode ? null : handleDiceRoll(c.name, c.value))}
        >
          <div className="character-sheetpage_characteristics_stat_name">
            {c.name}
          </div>
          <div className="character-sheetpage_characteristics_stat_value">
            {editMode ? (
              <input
                type="number"
                value={c.value}
                onChange={(e) =>
                  handleUpdateCharacteristic({ ...c, value: e.target.value })
                }
              />
            ) : (
              <span>{c.value}</span>
            )}
          </div>
        </div>
      ))}
      {editMode && (
        <button
          onClick={() =>
            handleSaveCharacter({
              ...character,
              characteristics,
            })
          }
        >
          Save
        </button>
      )}
    </div>
  )
}

export default CharacterCharacteristics
