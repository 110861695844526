import React, { useEffect, useRef, useState } from 'react'
import { useGlobalState } from '../context/GlobalStateContext'
import './AudioPlayer.css'

function AudioPlayer() {
  const { audioStreaming } = useGlobalState()
  const [sound, setSound] = useState(null)
  const [currentVolume, setCurrentVolume] = useState(localStorage.getItem('masterVolume') || 50)
  const audioRef = useRef(null)

  useEffect(() => {
    if (audioStreaming) {
      if (sound?._id !== audioStreaming._id) {
        setSound(audioStreaming)
      }
    } else {
      setSound(null)
    }
  }, [audioStreaming, sound])

  useEffect(() => {
    if (sound) {
      audioRef.current.src = sound.filePath
    }
  }, [sound])

  useEffect(() => {
    if (audioRef.current) {
      localStorage.setItem('masterVolume', currentVolume)
      audioRef.current.volume = currentVolume / 100
    }
  }, [currentVolume])

  const handlePlayAudio = () => {
    if (sound) {
      audioRef.current.src = sound.filePath
      audioRef.current.play()
    }
  }

  return (
    <div className="audio-player">
      {sound && (
        <audio ref={audioRef} controls hidden loop autoPlay={true}>
          <source src={sound.filePath} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      )}
      {sound && (
        <div className="audio-player-content">
          <button onClick={() => handlePlayAudio()}>Play</button>
          <button onClick={() => audioRef.current?.pause()}>Pause</button>
          <input
            type="range"
            min="0"
            max="100"
            value={currentVolume}
            onChange={(e) => setCurrentVolume(e.target.value)}
          />
        </div>
      )}
    </div>
  )
}

export default AudioPlayer
