import React, { useEffect, useState } from 'react'

function CharacterEquipments({ character, editMode, handleSaveCharacter }) {
  const [equipments, setEquipments] = useState([...character.equipments])
  const [addIndex, setAddIndex] = useState(1)

  useEffect(() => {
    if (!editMode) {
      setEquipments([...character.equipments])
    }
  }, [editMode, character])

  const handleUpdateEquipments = (index, value) => {
    const updatedEquipments = [...equipments]
    updatedEquipments[index] = value
    setEquipments(updatedEquipments)
  }

  const handleAddEquipment = () => {
    const updatedEquipments = [...equipments]
    updatedEquipments.push(`Nuovo equipaggiamento ${addIndex}`)
    setAddIndex(addIndex + 1)
    setEquipments(updatedEquipments)
  }

  return (
    <div className="character-sheetpage_equipments">
      {equipments.map((item, index) => (
        <div key={`equipments_${index}`}>
          <div className="row_equipment_name">
            {editMode ? (
              <input
                type="text"
                value={item}
                onChange={(e) => handleUpdateEquipments(index, e.target.value)}
              />
            ) : (
              item
            )}
          </div>
        </div>
      ))}
      {editMode ? (
        <>
          <button onClick={() => handleAddEquipment()}>
            Aggiungi equipaggiamento
          </button>
          <button
            onClick={() => handleSaveCharacter({ ...character, equipments })}
          >
            Salva
          </button>
        </>
      ) : null}
    </div>
  )
}

export default CharacterEquipments
