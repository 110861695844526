import React, { useEffect, useState } from 'react'

function CharacterBackground({ character, editMode, handleSaveCharacter }) {
  const [background, setBackground] = useState({ ...character.background })

  console.log(character)
  useEffect(() => {
    if (!editMode) {
      setBackground({ ...character.background })
    }
  }, [editMode, character])

  const handleUpdateBackground = (index, value) => {
    const updatedBackground = { ...background }
    updatedBackground[index] = value
    setBackground(updatedBackground)
  }

  const translateTitle = (index) => {
    switch (index) {
      case 'description':
        return 'Descrizione personale'
      case 'ideology':
        return 'Ideologia/Credo'
      case 'importantPeople':
        return 'Persone importanti'
      case 'significantPlaces':
        return 'Luoghi significativi'
      case 'valuables':
        return 'Oggetti di valore'
      case 'traits':
        return 'Tratti'
      case 'wounds':
        return 'Ferite & Cicatrici'
      case 'phobias':
        return 'Fobie & Manie'
      case 'knowledge':
        return 'Tomi arcani, Incantesimi & Artefatti'
      case 'meetings':
        return 'Incontri con Strane Entità'
      default:
        return index
    }
  }

  return (
    <div className="character-sheetpage_background">
      {Object.keys(background).map((item) => (
        <div key={`background_${item}`}>
            {editMode ? (
              <div className="row_background_block">
                <div className="row_background_block_title">
                  {translateTitle(item)}
                </div>
                <div className="row_background_block_content">
                  <textarea
                    value={background[item] || ''}
                    onChange={(e) =>
                      handleUpdateBackground(item, e.target.value)
                    }
                  />
                </div>
              </div>
            ) : (
              <div className={`row_background_block ${background[item] === '' ? 'hidden' : 'active'}`}>
                <div className="row_background_block_title">
                  {translateTitle(item)}
                </div>
                <div className="row_background_block_content">
                  {background[item]}
                </div>
              </div>
            )}
        </div>
      ))}
      {editMode ? (
        <button
          onClick={() => handleSaveCharacter({ ...character, background })}
        >
          Salva
        </button>
      ) : null}
    </div>
  )
}

export default CharacterBackground
