import React from 'react'
import CharacterDetailTemplate from './CharacterDetail/CharacterDetailTemplate'
// import { updateCharacter } from "../services/characters";

function CharacterDetail({ character, handleUpdateCharacter }) {
  return (
    <div className="character-detail">
      <CharacterDetailTemplate character={character} />
    </div>
  )
}

export default CharacterDetail
