import React, { useEffect, useState } from 'react'
import AvatarUploader from '../CharacterGenerator/AvatarUploader'

function CharacterInfoSection({ character, editMode, handleSaveCharacter }) {
  const [characterData, setCharacterData] = useState({
    ...character.characterInfo,
  })
  const [avatar, setAvatar] = useState(character.images.avatar)

  useEffect(() => {
    if (!editMode) {
      setCharacterData({ ...character.characterInfo })
      setAvatar(character.images.avatar)
    }
  }, [editMode, character])

  const handleUpdateCharacter = () => {
    console.log({
      ...character,
      characterInfo: characterData,
    })
    handleSaveCharacter({
      ...character,
      characterInfo: characterData,
      images: { ...character.images, avatar },
    })
  }

  return (
    <div className="character-sheetpage_header">
      <div className="character-sheetpage_header_left">
        <div>
          <b>Nome personaggio:</b>
          {editMode ? (
            <input
              type="text"
              value={characterData.name}
              onChange={(e) =>
                setCharacterData({ ...characterData, name: e.target.value })
              }
            />
          ) : (
            <span>{characterData.name}</span>
          )}
        </div>
        <div>
          <b>Soprannome:</b>
          {editMode ? (
            <input
              type="text"
              value={characterData.nickname}
              onChange={(e) =>
                setCharacterData({ ...characterData, nickname: e.target.value })
              }
            />
          ) : (
            <span>{characterData.nickname || characterData.name}</span>
          )}
        </div>
        <div>
          <b>Età:</b>
          {editMode ? (
            <input
              type="text"
              value={characterData.age}
              onChange={(e) =>
                setCharacterData({ ...characterData, age: e.target.value })
              }
            />
          ) : (
            <span>{characterData.age}</span>
          )}
        </div>
        <div>
          <b>Sesso:</b>
          {editMode ? (
            <select
              value={characterData.sex}
              onChange={(e) =>
                setCharacterData({ ...characterData, sex: e.target.value })
              }
            >
              <option
                value="Maschio"
                defaultChecked={characterData.sex === 'Maschio'}
              >
                Maschio
              </option>
              <option
                value="Femmina"
                defaultChecked={characterData.sex === 'Femmina'}
              >
                Femmina
              </option>
            </select>
          ) : (
            <span>{characterData.sex}</span>
          )}
        </div>
        <div>
          <b>Lavoro:</b>
          {editMode ? (
            <input
              type="text"
              value={characterData.work}
              onChange={(e) =>
                setCharacterData({ ...characterData, work: e.target.value })
              }
            />
          ) : (
            <span>{characterData.work}</span>
          )}
        </div>
        <div>
          <b>Residente a:</b>
          {editMode ? (
            <input
              type="text"
              value={characterData.residence}
              onChange={(e) =>
                setCharacterData({
                  ...characterData,
                  residence: e.target.value,
                })
              }
            />
          ) : (
            <span>{characterData.residence}</span>
          )}
        </div>
        <div>
          <b>Anno di nascita:</b>
          {editMode ? (
            <input
              type="text"
              value={characterData.bornage}
              onChange={(e) =>
                setCharacterData({ ...characterData, bornage: e.target.value })
              }
            />
          ) : (
            <span>{characterData.bornage}</span>
          )}
        </div>
        {editMode && (
          <div className="character-sheetpage_header_edit">
            <button onClick={() => handleUpdateCharacter()}>Salva</button>
          </div>
        )}
      </div>
      <div className="character-sheetpage_header_right">
        <div>
          {editMode ? (
            <AvatarUploader avatar={avatar} setAvatar={setAvatar} />
          ) : (
            <img src={`${avatar}`} alt="Avatar" />
          )}
        </div>
      </div>
    </div>
  )
}

export default CharacterInfoSection
