import React, { useEffect, useState } from 'react'
import IconPencil from './IconPencil'
import IconUndo from './IconUndo'
import CharacterInfoSection from './CharacterInfoSection'
import CharacterCharacteristics from './CharacterCharacteristics'
import CharacterPoints from './CharacterPoints'
import CharacterSkills from './CharacterSkills'
import CharacterWeapons from './CharacterWeapons'
import CharacterEquipments from './CharacterEquipments'
import CharacterCash from './CharacterCash'
import { updateCharacter } from '../../services/characters'
import CharacterBackground from './CharacterBackground'
import { useGlobalState } from '../../context/GlobalStateContext'

function CharacterDetailTemplate_Desktop({
  character,
  handleDiceRoll,
  handleDiceWeaponRoll,
}) {
  const [section, setSection] = useState('info')
  const [editMode, setEditMode] = useState(false)
  const [characterData, setCharacterData] = useState(character)
  const { reloadCharacters } = useGlobalState()

  useEffect(() => {
    setCharacterData(character)
  }, [character])

  const handleChangeSection = (section) => {
    setSection(section)
  }

  const handleSwitchEditMode = () => {
    setEditMode(!editMode)
  }

  const handleSaveCharacter = (newCharacter) => {
    updateCharacter(newCharacter).then((response) => {
      setCharacterData(response.character)
      setEditMode(false)
      reloadCharacters()
    })
  }

  if (!characterData) return null

  return (
    <div className="character-sheetpage desktop">
      {editMode ? (
        <h2>
          {characterData.characterInfo.name}{' '}
          <IconUndo onClick={() => handleSwitchEditMode()} />
        </h2>
      ) : (
        <h2>
          {characterData.characterInfo.name}{' '}
          <IconPencil onClick={() => handleSwitchEditMode()} />
        </h2>
      )}
      <div className="character-sheetpage_wrapper">
        <div className="character-sheetpage_sections">
          <ul>
            <li
              className={section === 'info' ? 'active' : 'inactive'}
              onClick={() => handleChangeSection('info')}
            >
              Informazioni
            </li>
            <li
              className={section === 'characteristics' ? 'active' : 'inactive'}
              onClick={() => handleChangeSection('characteristics')}
            >
              Caratteristiche
            </li>
            <li
              className={section === 'points' ? 'active' : 'inactive'}
              onClick={() => handleChangeSection('points')}
            >
              Punti
            </li>
            <li
              className={section === 'skills' ? 'active' : 'inactive'}
              onClick={() => handleChangeSection('skills')}
            >
              Abilità
            </li>
            <li
              className={section === 'weapons' ? 'active' : 'inactive'}
              onClick={() => handleChangeSection('weapons')}
            >
              Armi
            </li>
            <li
              className={section === 'equipments' ? 'active' : 'inactive'}
              onClick={() => handleChangeSection('equipments')}
            >
              Equipaggiamento
            </li>
            <li
              className={section === 'background' ? 'active' : 'inactive'}
              onClick={() => handleChangeSection('background')}
            >
              Background
            </li>
            <li
              className={section === 'cash' ? 'active' : 'inactive'}
              onClick={() => handleChangeSection('cash')}
            >
              Soldi
            </li>
          </ul>
        </div>
        <div className="character-sheetpage_sectioncontent">
          {section === 'info' && (
            <CharacterInfoSection
              character={characterData}
              editMode={editMode}
              handleSaveCharacter={handleSaveCharacter}
            />
          )}
          {section === 'characteristics' && (
            <CharacterCharacteristics
              editMode={editMode}
              character={characterData}
              handleDiceRoll={handleDiceRoll}
              handleSaveCharacter={handleSaveCharacter}
            />
          )}
          {section === 'points' && (
            <CharacterPoints
              editMode={editMode}
              character={characterData}
              handleDiceRoll={handleDiceRoll}
              handleSaveCharacter={handleSaveCharacter}
            />
          )}
          {section === 'skills' && (
            <CharacterSkills
              editMode={editMode}
              character={characterData}
              handleDiceRoll={handleDiceRoll}
              handleSaveCharacter={handleSaveCharacter}
            />
          )}
          {section === 'weapons' && (
            <CharacterWeapons
              editMode={editMode}
              character={characterData}
              handleDiceRoll={handleDiceWeaponRoll}
              handleSaveCharacter={handleSaveCharacter}
            />
          )}
          {section === 'equipments' && (
            <CharacterEquipments
              editMode={editMode}
              character={characterData}
              handleSaveCharacter={handleSaveCharacter}
            />
          )}
          {section === 'background' && (
            <CharacterBackground
              editMode={editMode}
              character={characterData}
              handleSaveCharacter={handleSaveCharacter}
            />
          )}
          {section === 'cash' && (
            <CharacterCash
              editMode={editMode}
              character={characterData}
              handleSaveCharacter={handleSaveCharacter}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default CharacterDetailTemplate_Desktop
