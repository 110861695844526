import React, { useEffect, useState } from 'react'

function WorkAndSkills({ character, setCharacter }) {
  const [work, setWork] = useState('')
  const [skills, setSkills] = useState([])
  const [updatedSkills, setUpdatedSkills] = useState([])
  const [formulaWork, setFormulaWork] = useState('')
  const [pointWork, setPointWork] = useState(0)
  const [pointInterest, setPointInterest] = useState(0)
  const [pointUsed, setPointUsed] = useState(0)

  useEffect(() => {
    const updateSkillValue = (arraySkills, name, minValue) => {
      const skill = arraySkills.find((skill) => skill.name === name)
      if (skill) {
        skill.minValue = minValue
      }
    }

    const characteristicsGetValue = (key) => {
      const row = character.characteristics.find(
        (characteristic) => characteristic.name === key
      )
      return parseInt(row?.value || 0)
    }

    if (character.characterInfo) {
      setWork(character.characterInfo.work)
    }
    if (character.characteristics) {
      const puntiIntelligenza = characteristicsGetValue('Intelligenza')
      const puntiIstruzione = characteristicsGetValue('Istruzione')
      const puntiDestrezza = characteristicsGetValue('Destrezza')
      setPointInterest(puntiIntelligenza * 2)

      let actSkills = character.skills || []
      if (actSkills) {
        updateSkillValue(actSkills, 'Lingua (Madre)', puntiIstruzione)
        updateSkillValue(actSkills, 'Schivare', parseInt(puntiDestrezza / 2))
        setSkills(actSkills)
      }
    }
  }, [character])

  useEffect(() => {
    const evaluateUsedPoints = (actSkills) => {
      let used = 0
      actSkills.forEach((skill) => {
        skill.value = parseInt(skill.value)
        skill.minValue = parseInt(skill.minValue)
        if (skill.value < skill.minValue) {
          skill.value = skill.minValue
        }
        if (skill.value > skill.maxValue) {
          skill.value = skill.maxValue
        }
        used += skill.value - skill.minValue
      })
      setPointUsed(used)
    }

    evaluateUsedPoints(skills)
    setUpdatedSkills(
      skills.filter(
        (skill) =>
          skill.value > skill.minValue || skill.name === 'Valore di credito'
      )
    )
  }, [skills])

  useEffect(() => {
    const formula = localStorage.getItem('formulaWork')
    if (formula) {
      setFormulaWork(formula)
    } else {
      setFormulaWork('Intelligenza x 2 + Istruzione x 2')
    }
  }, [])

  useEffect(() => {
    const calculateFormula = (formula) => {
      let total = 0

      const attributes = character.characteristics || []
      const termini = formula.split('+')
      for (let i = 0; i < termini.length; i++) {
        try {
          const terms = termini[i].trim().split(' ')

          const attribute = terms[0]
          const findChar = attributes.find((char) => char.name === attribute)
          if (!findChar) {
            return
          }

          const operator = terms[1]
          const value = parseInt(terms[2])
          if (operator === 'x') {
            total += findChar.value * value
          }
          if (isNaN(total)) {
            return
          }
        } catch (error) {
          return
        }
      }

      return total
    }

    const formulaWork =
      localStorage.getItem('formulaWork') || 'Intelligenza x 2 + Istruzione x 2'
    const point = calculateFormula(formulaWork)
    setPointWork(point || 'Formula non valida')
  }, [formulaWork, character.characteristics])

  const handleChangeFormulaWork = (e) => {
    e.preventDefault()
    localStorage.setItem('formulaWork', e.target.value)
    setFormulaWork(e.target.value)
  }

  const handleChangeSkillValue = (name, value) => {
    if (!value) {
      return
    }
    const findSkill = skills.find((skill) => skill.name === name)
    if (findSkill) {
      findSkill.value = isNaN(parseInt(value)) ? 0 : parseInt(value)
      if (findSkill.value > findSkill.maxValue) {
        findSkill.value = findSkill.maxValue
      }
    }
    setSkills([...skills])
  }

  const handleChangeSkillName = (oldName, newName) => {
    const findSkill = skills.find((skill) => skill.name === oldName)
    if (findSkill) {
      findSkill.sourceSkill = findSkill.sourceSkill
        ? findSkill.sourceSkill
        : oldName
      findSkill.name = newName
    }
    setSkills([...skills])
  }

  const handleCreateNewSkill = (skill) => {
    if (skill.sourceSkill === '') {
      return
    }
    if (skill.name === '') {
      return
    }
    const sourceSkill = skills.find((s) => s.name === skill.sourceSkill)
    const updatedSkill = skills.find((s) => s.name === skill.name)
    if (!sourceSkill) {
      const newSkill = { ...updatedSkill }
      newSkill.name = skill.sourceSkill
      console.log('Nuova abilità', newSkill)
      setSkills([...skills, newSkill])
    }
  }

  const handleChangeDetailSkillValue = (name, value) => {
    if (!value) {
      return
    }
    const findSkill = skills.find((skill) => skill.name === name)
    if (findSkill) {
      findSkill.value = isNaN(parseInt(value)) ? 0 : parseInt(value)
      if (findSkill.value > findSkill.maxValue) {
        findSkill.value = findSkill.maxValue
      }
    }
    setSkills([...skills])
  }

  const handleSaveStep = () => {
    const actCharacter = character
    actCharacter.characterInfo.work = work
    actCharacter.skills = skills
    setCharacter({ ...actCharacter })
  }

  const saveButton = () => {
    const checkPoints = pointInterest + pointWork - pointUsed === 0
    const checkWork = work !== ''
    const enableButton = checkPoints && checkWork
    if (enableButton) {
      return <button onClick={() => handleSaveStep()}>Salva e continua</button>
    } else {
      return (
        <>
          <button disabled>Salva e continua</button>
          {!checkWork && <div className="error">Inserire un lavoro</div>}
          {!checkPoints && (
            <div className="error">
              I punti non sono stati assegnati correttamente
            </div>
          )}
        </>
      )
    }
  }

  return (
    <div className="character-step-workandskills">
      <div className="character-step-work">
        <h2>Occupazione</h2>
        <div className="character-step-work-content">
          <div>
            <label>Lavoro</label>
            <input
              type="text"
              className={work === '' ? 'invalid' : ''}
              value={work}
              onChange={(e) => setWork(e.target.value)}
            />
          </div>
          <div>
            <label>Formula</label>
            <input
              type="text"
              value={formulaWork}
              className={pointWork === 'Formula non valida' ? 'invalid' : ''}
              placeholder="Esempio: Intelligenza x 2 + Istruzione x 2"
              onChange={(e) => handleChangeFormulaWork(e)}
            />
          </div>
          <div>
            <label>Punti Lavoro</label>
            <input type="text" readOnly value={pointWork} />
          </div>
          <div>
            <label>Punti Interessi personali</label>
            <input type="text" readOnly value={pointInterest} />
          </div>
          <div>
            <label>Punti Spesi</label>
            <input type="text" readOnly value={pointUsed} />
          </div>
          <div>
            <label>Punti Residui</label>
            <input
              type="text"
              readOnly
              value={pointInterest + pointWork - pointUsed}
            />
          </div>
        </div>
        {updatedSkills.length > 0 && (
          <div className="workandskills-updatedskills">
            <h2>Abilità Aggiornate</h2>
            <div className="workandskills-updatedskills-content">
              <div className="table_skill_row">
                <div className="table_skill_name">Nome</div>
                <div className="table_skill_value">Valore</div>
                <div className="table_skill_used">Spesi</div>
              </div>
              {updatedSkills?.map((skill, index) => (
                <div key={index} className="table_skill_row">
                  <div className="table_skill_name">{skill.name}</div>
                  <div className="table_skill_value">
                    <input
                      type="number"
                      onChange={(e) =>
                        handleChangeDetailSkillValue(skill.name, e.target.value)
                      }
                      value={skill.value}
                      min={skill.minValue}
                      max={skill.maxValue}
                    />
                  </div>
                  <div className="table_skill_used">
                    {skill.value - skill.minValue}
                  </div>
                </div>
              ))}
            </div>
            <div className="workandskills-submit">{saveButton()}</div>
          </div>
        )}
      </div>
      <div className="character-step-skills-wrapper">
        <div className="character-step-skills">
          <h2>Abilità</h2>
          <div className="character-step-skills-contentskills">
            <div className="character-step-skills-content">
              {skills
                ?.sort((a, b) => a.name?.localeCompare(b.name))
                .map((skill, index) => (
                  <div key={index}>
                    <label>{skill.sourceSkill}</label>
                    <input
                      value={skill.name}
                      onBlur={() => handleCreateNewSkill(skill)}
                      onChange={(e) =>
                        handleChangeSkillName(skill.name, e.target.value)
                      }
                    />
                    <input
                      className={skill.value < skill.minValue ? 'invalid' : ''}
                      type="number"
                      value={isNaN(skill.value) ? 0 : skill.value}
                      min={skill.minValue}
                      max={skill.maxValue}
                      onChange={(e) =>
                        handleChangeSkillValue(skill.name, e.target.value)
                      }
                    />
                    <div className="skill_notice">
                      Valore minimo {skill.minValue}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="workandskills-updatedskills-showlist"></div>
      </div>
    </div>
  )
}

export default WorkAndSkills
