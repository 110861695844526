import React from 'react'
import './CharacterDetailTemplate.css'
import { useGlobalState } from '../../context/GlobalStateContext'
import CharacterDetailTemplateDesktop from './CharacterDetailTemplate_Desktop'
import CharacterDetailTemplateMobile from './CharacterDetailTemplate_Mobile'

function CharacterDetailTemplate({ character }) {
  const { socket, sendSocketMessage, isMobile } = useGlobalState()

  if (!character) {
    return <div>Loading...</div>
  }

  const returnLevel = (dice, value) => {
    if (dice === 1) return 'critic'
    if (dice <= 0.2 * value) return 'extreme'
    if (dice <= 0.5 * value) return 'arduous'
    if (dice <= value) return 'normal'
    if (dice >= 99) return 'extrafailed'
    return 'failed'
  }

  const handleDiceRoll = (name, value) => {
    const d10 = () => {
      return Math.floor(Math.random() * 10) + 1
    }
    const dice = (d10() - 1) * 10 + d10()
    const level = returnLevel(dice, value)
    const message = `<b>${character.characterInfo.name}</b> ha tirato su <b>${name}</b> ottenendo <b>${dice}</b> su <b>${value}</b>`
    const nickname =
      character.characterInfo.nickname || character.characterInfo.name
    let speachText = null
    switch (level) {
      case 'critic':
        speachText = `<b>${nickname}</b> ha ottenuto un critico su <b>${name}</b>`
        break
      case 'extreme':
        speachText = `<b>${nickname}</b> ha ottenuto un tiro estremo su <b>${name}</b>`
        break
      case 'arduous':
        speachText = `<b>${nickname}</b> ha ottenuto un tiro arduo su <b>${name}</b>`
        break
      case 'normal':
        speachText = `<b>${nickname}</b> ha ottenuto un successo normale su <b>${name}</b>`
        break
      case 'failed':
        speachText = `<b>${nickname}</b> ha tirato su <b>${name}</b> fallendo`
        break
      case 'extrafailed':
        speachText = `<b>${nickname}</b> ha ottenuto un fallimento critico su <b>${name}</b>`
        break
      default:
        break
    }

    if (socket) {
      sendSocketMessage('DICE_ROLL', { message, level, speachText })
    }
  }

  const handleDiceWeaponRoll = (weapon) => {
    const BD = character.points.find((point) => point.name === 'Bonus al Danno')
    const evaluateDamage = (damage) => {
      return damage
        .toLowerCase()
        .replace('+bd', `${BD.value < 0 ? BD.value : `+${BD.value}`}`)
    }
    const rollDice = (faces) => {
      return Math.floor(Math.random() * faces) + 1
    }
    const evaluateExpression = (expression) => {
      const components = evaluateDamage(expression)
        .toLowerCase()
        .split(/(\+|-)/)
        .map((str) => str.trim())

      let result = 0
      let maxResult = 0
      let lastOperation = '+'

      components.forEach((component) => {
        if (component.includes('d')) {
          const [numberOfDice, faces] = component
            .split('d')
            .map((str) => parseInt(str))

          let total = 0
          let maxTotal = numberOfDice * faces * (lastOperation === '+' ? 1 : -1)
          for (let i = 0; i < numberOfDice; i++) {
            total += rollDice(faces)
          }

          if (lastOperation === '-') {
            total *= -1
            maxTotal *= -1
          }
          result += total
          maxResult += maxTotal
        } else {
          if (component !== '+' && component !== '-') {
            let modifier = parseInt(component)
            if (lastOperation === '-') {
              modifier *= -1
            }
            result += modifier
            maxResult += modifier
          } else {
            lastOperation = component
          }
        }
      })

      return {
        result: result < 0 ? 0 : result,
        maxResult: maxResult < 0 ? 0 : maxResult,
      }
    }

    const skillValue =
      character.skills.find((skill) => skill.name === weapon.associatedSkill)
        ?.value || 0

    const dice = (rollDice(10) - 1) * 10 + rollDice(10)
    const level = returnLevel(dice, skillValue)
    if (dice > skillValue) {
      const message = `<b>${character.characterInfo.name}</b> ha usato <b>${weapon.name}</b> [${weapon.associatedSkill}]: Ha ottenuto <b>${dice}/${skillValue}</b>`
      const nickname =
        character.characterInfo.nickname || character.characterInfo.name
      let speachText = null
      switch (level) {
        case 'failed':
          speachText = `<b>${nickname}</b> non è riuscito ad usare <b>${weapon.name}</b>`
          break
        case 'extrafailed':
          speachText = `<b>${nickname}</b> non è riuscito ad usare <b>${weapon.name}</b> facendo una figura di merda`
          break
        default:
          break
      }
      if (socket) {
        sendSocketMessage('DICE_ROLL', { message, level, speachText })
      }
      return
    }

    const damage = evaluateExpression(weapon.damage.replace(/ /g, ''))
    let evalDamage = damage.result
    if (level === 'extreme' || level === 'critic') {
      evalDamage += damage.maxResult
    }

    const nickname =
      character.characterInfo.nickname || character.characterInfo.name
    let message = `<b>${character.characterInfo.name}</b> ha usato <b>${weapon.name}</b> [${weapon.associatedSkill}]: Ha ottenuto <b>${dice}/${skillValue}</b> e infligge ${evalDamage} danni`
    let speachText = null
    switch (level) {
      case 'critic':
        speachText = `<b>${nickname}</b> ha fatto un tiro critico con <b>${weapon.name}</b> facendo ${evalDamage} danni`
        break
      case 'extreme':
        speachText = `<b>${nickname}</b> ha fatto un tiro estremo con <b>${weapon.name}</b> facendo ${evalDamage} danni`
        break
      case 'arduous':
        speachText = `<b>${nickname}</b> ha fatto un tiro arduo con <b>${weapon.name}</b> facendo ${evalDamage} danni`
        break
      case 'normal':
        speachText = `<b>${nickname}</b> ha usato <b>${weapon.name}</b> facendo ${evalDamage} danni`
        break
      default:
        break
    }
    if (socket) {
      sendSocketMessage('DICE_ROLL', { message, level, speachText })
    }
  }

  if (isMobile) {
    return (
      <CharacterDetailTemplateMobile
        character={character}
        handleDiceRoll={handleDiceRoll}
        handleDiceWeaponRoll={handleDiceWeaponRoll}
      />
    )
  } else {
    return (
      <CharacterDetailTemplateDesktop
        character={character}
        handleDiceRoll={handleDiceRoll}
        handleDiceWeaponRoll={handleDiceWeaponRoll}
      />
    )
  }
}

export default CharacterDetailTemplate
