import React, { useEffect, useState } from 'react'
import slugify from 'slugify'

function CharacterPoints({
  character,
  handleDiceRoll,
  editMode,
  handleSaveCharacter,
}) {
  const [points, setPoints] = useState([...character.points])

  useEffect(() => {
    if (!editMode) {
      setPoints([...character.points])
    }
  }, [editMode, character])

  const handleUpdatePoints = (point) => {
    setPoints(points.map((p) => (p.name === point.name ? point : p)))
  }

  const puntiFortuna = points.find((point) => point.name === 'Punti Fortuna')
  const puntiSanita = points.find((point) => point.name === 'Punti Sanità')

  return (
    <div className="character-sheetpage_points">
      {points.map((point) => (
        <div
          key={`points_${slugify(point.name.toLocaleLowerCase())}`}
          className="character-sheetpage_points_stat"
        >
          <div className="character-sheetpage_points_stat_name">
            <span>{point.name}</span>
          </div>
          <div className="character-sheetpage_points_stat_value">
            {editMode ? (
              <input
                type="text"
                value={point.value}
                onChange={(e) =>
                  handleUpdatePoints({ ...point, value: e.target.value })
                }
              />
            ) : (
              <>
                <span>{point.value}</span>
                {point.maxValue && <span>{point.maxValue}</span>}
              </>
            )}
          </div>
        </div>
      ))}
      <div className="character-sheetpage_points_stat">
        {!editMode && (
          <button
            onClick={() => handleDiceRoll('Tiro Fortuna', puntiFortuna.value)}
          >
            Tiro Fortuna
          </button>
        )}
      </div>
      <div className="character-sheetpage_points_stat">
        {editMode ? (
          <button
            onClick={() =>
              handleSaveCharacter({
                ...character,
                points,
              })
            }
          >
            Salva
          </button>
        ) : (
          <button
            onClick={() => handleDiceRoll('Tiro Sanità', puntiSanita.value)}
          >
            Tiro Sanità
          </button>
        )}
      </div>
    </div>
  )
}

export default CharacterPoints
