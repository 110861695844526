const TextToSpeechService = (text, lang = 'it-IT') => {
  return
  const synth = window.speechSynthesis;

  if (synth.speaking) {
    setTimeout(() => {
      TextToSpeechService(text, lang);
    }, 500);
    return
  }

  const utterance = new SpeechSynthesisUtterance(text);
  utterance.lang = lang;
  utterance.volume = 1;
  utterance.rate = 1;
  utterance.pitch = 1;
  const voices = synth.getVoices();
  const voice = voices.find(v => v.lang === lang && v.name !== 'Google US English');
  if (voice) {
    utterance.voice = voice; 
  }
  synth.speak(utterance);
};

export default TextToSpeechService;
