import React from 'react'
import CharacterInfoSection from './CharacterInfoSection'
import CharacterCharacteristics from './CharacterCharacteristics'
import CharacterPoints from './CharacterPoints'
import CharacterSkills from './CharacterSkills'
import CharacterWeapons from './CharacterWeapons'
import CharacterEquipments from './CharacterEquipments'
import CharacterCash from './CharacterCash'

function CharacterDetailTemplate_Mobile({
  character,
  handleDiceRoll,
  handleDiceWeaponRoll,
}) {
  return (
    <div className="character-sheetpage mobile">
      <CharacterInfoSection character={character} />
      <CharacterCharacteristics
        character={character}
        handleDiceRoll={handleDiceRoll}
      />
      <CharacterPoints character={character} handleDiceRoll={handleDiceRoll} />
      <CharacterSkills character={character} handleDiceRoll={handleDiceRoll} />
      <CharacterWeapons
        character={character}
        handleDiceRoll={handleDiceWeaponRoll}
      />
      <CharacterEquipments character={character} />
      <CharacterCash character={character} />
    </div>
  )
}

export default CharacterDetailTemplate_Mobile
